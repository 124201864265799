import React, {useEffect, useState} from 'react'
import {Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

function SearchResult() {
  const [searchItems, setSearchItems] = useState([]);
	let params = useParams();

	const getSearchItem = async (name) => {
		const data = await fetch(
			`https://api.spoonacular.com/recipes/complexSearch?apiKey=${process.env.REACT_APP_API_KEY}&query=${name}`
		)
		const recipes = await data.json();
	
		setSearchItems(recipes.results);
	}

	useEffect(()=>{
		console.log(params.search);
		getSearchItem(params.search);
	},[params.search])

  return (
    <Grid>
      {
        searchItems?.map((item) => {
          return(
            <Card key={item.id}>
              <Link to={'/recipe/'+ item.id}>
                <img src={item.image} alt="" />
                <h4>{item.title}</h4>
              </Link>
						</Card>
          )
        })
      }
    </Grid>
  )
}

const Grid = styled.div `
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	grid-gap: 3rem;
`;

const Card = styled.div `
	img {
		width: 100%;
		border-radius: 2rem;
	}

	a {
		text-decoration: none;
	}

	h4 {
		text-algin: center;
		padding: 1rem;
	}
`;

export default SearchResult;